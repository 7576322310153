import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128')
];

export const server_loads = [0,4,5,6,7,8,9,10,11,12,13,14,15,16,18,19,20];

export const dictionary = {
		"/": [21],
		"/board_list/board": [~22,[2]],
		"/board_list/board/[board_id]": [~23,[2]],
		"/board_list/board/[board_id]/article/[article_id]": [~24,[2]],
		"/board_list/review": [~25,[2]],
		"/crm/notification": [~26,[3]],
		"/crm/notification/[id]": [~27,[3]],
		"/customer_list/blocked_postalcode": [~28,[4]],
		"/customer_list/customers": [~29,[4]],
		"/customer_list/customers/[id]/basic": [30,[4,5]],
		"/customer_list/customers/[id]/cart": [~31,[4,5]],
		"/customer_list/customers/[id]/coupon": [~32,[4,5]],
		"/customer_list/customers/[id]/credit": [~33,[4,5]],
		"/customer_list/customers/[id]/like": [~34,[4,5]],
		"/customer_list/customers/[id]/order": [~35,[4,5]],
		"/customer_list/customers/[id]/point": [~36,[4,5]],
		"/customer_list/customers/[id]/profile_history": [~37,[4,5]],
		"/customer_list/group_management": [~38,[4]],
		"/customer_list/group_management/[id]": [~39,[4]],
		"/customer_list/mail_template": [~40,[4]],
		"/customer_list/mail_template/[id]": [~41,[4]],
		"/download": [42,[6]],
		"/login": [43],
		"/manager_team": [44,[7]],
		"/manager_team/[team]": [~45,[7]],
		"/manufacturer_supplyorder/excel-upload": [48,[8]],
		"/manufacturer_supplyorder/list": [~49,[8]],
		"/manufacturer_supplyorder/[code]": [~46,[8]],
		"/manufacturer_supplyorder/[code]/shipping": [~47,[8]],
		"/offline/customers": [~50,[9]],
		"/offline/customers/[id]/basic": [51,[9,10]],
		"/offline/customers/[id]/point": [~52,[9,10]],
		"/order": [~53,[11]],
		"/order/bulkcancel": [~55,[11]],
		"/order/email_history": [~56,[11]],
		"/order/input_shipping": [~57,[11]],
		"/order/survey": [58,[11]],
		"/order/verification": [~59,[11]],
		"/order/[code]": [~54,[11]],
		"/products/category": [~60,[12]],
		"/products/excel_upload": [61,[12]],
		"/products/guide-images": [~62,[12]],
		"/products/guide-images/[id]": [~63,[12]],
		"/products/list": [~64,[12]],
		"/products/list/[id]": [~65,[12]],
		"/products/manufacturer": [~66,[12]],
		"/products/manufacturer/[id]": [~67,[12]],
		"/products/productvariant_excel_upload": [~68,[12]],
		"/products/productvariants": [~69,[12]],
		"/products/retailstore": [~70,[12]],
		"/products/retailstore/[id]": [~71,[12]],
		"/products/sync": [~72,[12]],
		"/products/warehouse": [~73,[12]],
		"/products/warehouse/[id]": [~74,[12]],
		"/products/warehouse/[id]/stock": [~75,[12]],
		"/promotion/bannergroups": [~76,[13]],
		"/promotion/bannergroups/[id]": [~77,[13]],
		"/promotion/benefit-seq": [~80,[13]],
		"/promotion/benefit": [~78,[13]],
		"/promotion/benefit/[id]": [~79,[13]],
		"/promotion/coupon_settings": [~81,[13]],
		"/promotion/coupon_settings/[id]": [~82,[13]],
		"/promotion/coupon_settings/[id]/coupons": [~83,[13]],
		"/promotion/displaygroups-seq": [~90,[13]],
		"/promotion/displaygroups": [~84,[13]],
		"/promotion/displaygroups/[id]": [~85,[13]],
		"/promotion/displaygroups/[id]/comments": [~86,[13]],
		"/promotion/displaygroups/[id]/connecting_displaygroup": [~87,[13]],
		"/promotion/displaygroups/[id]/connecting_product": [~88,[13]],
		"/promotion/displaygroups/[id]/manage_product": [~89,[13]],
		"/promotion/order_events": [~91,[13]],
		"/promotion/order_events/[id]": [~92,[13]],
		"/promotion/reels": [~93,[13]],
		"/promotion/reels/[id]": [~94,[13]],
		"/sellers": [~95,[14]],
		"/sellers/manual": [97,[14]],
		"/sellers/manufacturer": [~98,[14]],
		"/sellers/manufacturer/[id]": [~99,[14]],
		"/sellers/stat/[id]": [~100,[14]],
		"/sellers/[id]": [~96,[14]],
		"/settings/adminlog": [~101,[15]],
		"/settings/staff": [~102,[15]],
		"/settings/staff/[staff_id]": [~103,[15]],
		"/settings/store": [~104,[15]],
		"/settlement": [~105,[16]],
		"/settlement/adjustment": [~106,[16]],
		"/settlement/excel-upload": [107,[16]],
		"/settlement/invoice": [~108,[16]],
		"/settlement/invoice/[id]": [~109,[16]],
		"/settlement/list": [~110,[16]],
		"/stats/business": [111,[17]],
		"/stats/conversions": [112,[17]],
		"/stats/conversions/[id]": [113,[17]],
		"/stats/custom": [114,[17]],
		"/stats/dashboard": [115,[17]],
		"/stats/sales": [116,[17]],
		"/storeentry": [~117,[18]],
		"/storeentry/[id]": [~118,[18]],
		"/supplyorder/cart": [~121,[19]],
		"/supplyorder/excel-upload": [122,[19]],
		"/supplyorder/item-list": [~123,[19]],
		"/supplyorder/jobresult": [124,[19]],
		"/supplyorder/jobresult/[id]": [~125,[19]],
		"/supplyorder/list": [~126,[19]],
		"/supplyorder/[code]": [~119,[19]],
		"/supplyorder/[code]/shipping": [~120,[19]],
		"/temp-sentry-test": [127],
		"/warehouse_inbound": [~128,[20]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';